import type { Entry } from 'contentful';
import type { IAccordionSection } from '../lib/ContentfulService/generated/contentful';
import type {
  ICarouselSection,
  ICtaSection,
  IHeroArticleSection,
  IHeroProductSection,
  IProductList,
  ISection,
  ITabSection
} from '~/lib/ContentfulService';

type GroupedSection = {
  section?: ISection;
  items: Entry<unknown>[];
  type: 'section' | 'sectionEntry' | 'sectionItemEntry';
};

function isSection(item: Entry<unknown> | undefined): item is ISection {
  // XXX: hardcoded value?
  return item?.sys?.contentType?.sys?.id === 'section';
}

function isSectionItem(
  item: Entry<unknown> | undefined
): item is
  | ICarouselSection
  | ITabSection
  | ICtaSection
  | IHeroArticleSection
  | IAccordionSection
  | IHeroProductSection
  | IProductList {
  const sectionItems = [
    'carouselSection',
    'tabSection',
    'ctaSection',
    'heroArticleSection',
    'accordionSection',
    'heroProductSection',
    'productList'
  ];
  return Boolean(
    item?.sys?.contentType?.sys?.id &&
      sectionItems.includes(item.sys.contentType.sys.id)
  );
}

/**
 * Groups the given list of items by section entry of the given list.
 * A section group is onlu created, once a section entry is present.
 * E.g., if the list does not start with a section entry, all entries
 * are ignored until the first section entry.
 *
 * @param items The items to group.
 */
export function groupSections(
  items?: ReadonlyArray<Entry<unknown>>
): GroupedSection[] {
  if (!items) {
    return [];
  }

  const sections: GroupedSection[] = [];

  for (const item of items) {
    if (isSection(item)) {
      sections.push({
        type: 'sectionEntry',
        section: item,
        items: []
      });

      continue;
    }

    if (isSectionItem(item)) {
      sections.push({ type: 'sectionItemEntry', items: [item] });

      // section items hold only one item
      // so a new section must be provided for the following items
      sections.push({
        type: 'section',
        items: []
      });

      continue;
    }

    if (sections.length === 0) {
      sections.push({
        type: 'section',
        items: [item]
      });

      continue;
    }

    sections.at(-1)?.items.push(item);
  }

  return sections.filter((section) => section.items.length);
}
